@media only screen and (max-width: 800px) {
  .translate-component {
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .good-local-causes {
    flex-direction: column;
  }
  body {
    padding: 0;
  }
}

@media only screen and (min-width: 800px) {
  .translate-component {
    margin-left: 14rem;
    margin-right: 14rem;
    width: 700px;
  }
  .good-local-causes {
    flex-direction: row;
  }
}

body {
  text-align: center;
  font-family: 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode',
    'Lucida Sans', Tahoma, sans-serif;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  max-height: 50%;
  max-width: 50%;
  transition: all 0.2s ease-in-out;
}
.logo-nav {
  text-decoration: none;
  border: none;
}
.logo-nav a {
  border: none;
}

.logo:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 12px;
}

.swap-arrows {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 10vh;
  width: 20px;
  /* padding-left: 8px;
  padding-right: 8px; */
  cursor: pointer;
}

.translate-component {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  background-color: white;
}

.translate-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  height: 4rem;
  background-color: #eb172b;
  color: white;
  font-weight: bolder;
}

.translate-header-item {
  display: flex;
  flex-direction: row;
  width: 60px;
}
.translate-text-area-input {
  height: 200px;
  border: none;
  margin: 12px;
  justify-content: center;
  resize: none;
  font-size: 14px;
  font-weight: semibold;
}

.fade-text {
  animation: fade-in 1s;
  animation-fill-mode: forwards;
}
.shake-text {
  animation: shake-animation 1s;
  animation-fill-mode: forwards;
}
@keyframes shake-animation {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(10px);
  }
  50% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.translate-text-area {
  height: 200px;
  border: none;
  color: white;
  font-weight: bold;
  border-radius: 4px;
  resize: none;
  font-size: 14px;
  background-color: #eb172b;
  margin: 8px;
  padding-top: 12px;
  padding-left: 12px;
}
.translate-text-area::placeholder {
  color: #f4adb8;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.good-causes-logo {
  height: 100px;
  text-align: center;
  padding: 10px 20px;
}

.foodbank-logo {
  height: 100px;
  pointer-events: none;
  text-align: center;
  padding: 10px 20px;
}

.foodbank-link {
  color: white;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: bolder;
  padding: 3px 10px;
  border: 1px solid #eb172b;
  background-color: #eb172b;
  border-radius: 12px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-item {
  margin-right: 8px;
  text-decoration: none;
  border-bottom: 3px solid #eb172c1c;
  color: inherit;
}

.nav-item-left {
  margin-top: 6px;
  margin-left: 8px;
  margin-right: auto;
  justify-content: flex-start;
}
a {
  text-decoration: none;
  border-bottom: 3px solid #eb172c1c;
  color: inherit;
}
a:hover {
  border-bottom-color: #fff9;
}

.geordify {
  padding: 12px;
}

.hello-nav a {
  color: inherit;
  text-decoration: none;
  border-bottom: 3px solid #eb172c1c;
}

.hello-nav a:hover {
  border-bottom-color: #fff9;
}

.mackem-dictionary {
  padding: 12px;
}

/* .geordify-nav {
  text-decoration: none;
  cursor: pointer;
  font-size: bold;
}
.mackem-dictionary-nav {
  text-decoration: none;
  cursor: pointer;
  font-size: bold;
} */

h2 {
  font-size: 1.5rem;
  font-weight: bolder;
  margin-bottom: 1rem;
  color: white;
  background-color: #eb172b;
  padding: 6px;
  border-radius: 3px;
  height: 30px;
  background-image: linear-gradient(to right, #eb172b, #ef5867);
}
.about {
}
.red-banner {
  color: white;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: bolder;
  padding: 3px 10px;
  border: 1px solid #eb172b;
  background-color: #eb172b;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.white-banner {
  color: white;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: bolder;
  padding: 3px 10px;
  border: 1px solid #eb172b;
  background-color: #eb172b;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.more-info {
  display: flex;
  flex-direction: column;
}
.more-info-section {
  padding: 0 12px 12px 12px;
  text-align: left;
  font-size: 0.9rem;
}
.more-info-header {
}

.good-local-causes {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  text-align: left;
  font-size: 0.9rem;
}

.add-to-our-dictionary {
}

.more-info-column-row {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.about-text {
  padding: 3px;
}
